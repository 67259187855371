#side_menu {
    position: fixed;
    z-index: 110;
    top: 0;
    left: -5px;
    width: 60%;
    max-width: 350px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: all .4s ease;
    border-top: none;
    background-color: var(--sidebar_background);
    user-select: none;
}

.side_menu_off {
    transform: translateX(-350px);
    box-shadow: unset;
    pointer-events: none;
}

.side_menu_on {
    transform: translateX(0px);
    box-shadow: 3px 0 18px rgba(0, 0, 0, 0.6);
}

#side_menu_top_section {
    height: 100px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: var(--border_default);
    border-right: none;
    border-left: none;
    padding: 15px;
    background-color: var(--sidebar_top_section_background);
    cursor: pointer;
}

#side_menu_top_section:hover #side_menu_close_icon {
    transform: scale(1.1);
}

#side_menu_top_section:active #side_menu_close_icon {
    transform: scale(1);
}


#side_menu_title {
    font-family: var(--font_general);
    font-size: 28px;
    padding-left: 5%;
    cursor: default;
    transition: all .2s ease;
    color: var(--sidebar_title);
}

#side_menu_close_icon {
    width: 26px;
    transition: all .2s ease;
    filter: invert(100%);
}

#links {
    height: auto;
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

#link {
    background-color: transparent;
    color: var(--sidebar_label);
    border: none;
    height: 80px;
    width: 100%;
    cursor: pointer;
    font-size: 20px;
}

#link:hover {
    background-color: #e8e8e8;
}

#dark_mode {
    height: 100px;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    font-size: 20px;
    font-weight: 500;
    color: rgb(50, 50, 50);
}

#dark_mode_label {
    font-family: var(--font_general);
    cursor: pointer;
    color: var(--sidebar_label);
}

#dark_mode_button {
    border: none;
    background-color: transparent;
    cursor: pointer;
}

#dark_mode_container {
    height: 24px;
    width: 50px;
    border-radius: 12px;
    border: rgb(80, 80, 80) 2px solid;
    transition: all .3s ease;
}

.dark_mode_container_off {
    background-color: white;
}

.dark_mode_container_on {
    background-color: rgb(80, 80, 80);
}

#dark_mode_switch {
    width: 14px;
    height: 14px;
    border-radius: 100%;
    margin-top: 3px;
    transition: all .3s ease;
}

.dark_mode_switch_off {
    background-color: rgb(80, 80, 80);
    margin-left: 3px;
}

.dark_mode_switch_on {
    background-color: white;
    margin-left: 29px;
}