#edit_password_page {
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

#edit_password_email_input {
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

#edit_password_email_title {
    font-family: var(--font_general);
    font-size: 36px;
    font-weight: 700;
}

#edit_password_input_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

#edit_password_email_description {
    font-family: var(--font_general);
    font-size: 20px;
    max-width: 80vw;
    text-align: center;
}

#edit_password_input {
    width: 90vw;
    max-width: 500px;
    padding: 20px;
    border: rgba(0, 0, 0, 0.5) 1px solid;
    border-radius: 30px;
    font-family: var(--font_general);
    font-size: 20px;
    outline: none;
}

#edit_password_submit {
    width: 150px;
    padding: 15px;
    font-family: var(--font_general);
    font-size: 18px;
    background-color: var(--color_blueberry);
    color: white;
    border: none;
    border-radius: 100px;
    box-shadow: 3px 3px 10px 1px rgba(0, 0, 0, 0.5);
    cursor: pointer;
    outline: none;
    transition: all .1s ease;
}

#edit_password_submit:hover {
    transform: scale(1.08);
}

#edit_password_submit:active {
    transform: scale(1);
}

#edit_password_email_sent {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    pointer-events: none;
}

#edit_password_email_sent_content {
    width: 85%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12vh;
    opacity: 0;
}

#edit_password_email_sent_content p {
    font-family: var(--font_general);
    font-size: 24px;
    text-align: center;
}

#reset_passwordlogin_button_email_sent {
    height: 45px;
    width: 150px;
    border-radius: 20px;
    color: white;
    font-size: 18px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
    border: none;
    background-color: var(--color_blueberry);
    user-select: none;
    cursor: pointer;
    opacity: 0;
    transition: all .2s ease;
}

#reset_passwordlogin_button_email_sent:hover {
    scale: 1.08;
}

#reset_passwordlogin_button_email_sent:active {
    scale: 1;
}


@keyframes edit_password_blur {
    from {
        opacity: 0;
        pointer-events: none;
    }

    to {
        opacity: 1;
        pointer-events: auto;
        background-color: rgba(255, 255, 255, 0.4);
        backdrop-filter: blur(3px);
    }
}

@keyframes edit_password_move_in {
    from {
        transform: translateY(400px);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@media screen and (max-width: 900px) {
    #edit_password_email_input {
        height: 60vh;
        justify-content: space-between;
    }

    #edit_password_email_title {
        font-size: 26px;
    }

    #edit_password_email_description {
        font-size: 18px;
        max-width: 95vw;
    }

    #edit_password_input {
        width: 90vw;
        max-width: 500px;
        padding: 16px;
        padding-left: 30px;
        border-radius: 30px;
        font-family: var(--font_general);
        font-size: 16px;
    }

    #edit_password_email_sent_content {
        height: 28vh;
        justify-content: space-between;
    }

    #edit_password_email_sent_content p {
        font-size: 20px;
    }
}