:root {
  --border_default: rgba(50, 50, 50, 0.3) 1px solid;
  --font_title: 'Inter', sans-serif;
  --font_general: 'Sanchez', serif;

  /*_________________________MAIN_COLORS_________________________*/
  --color_light_blue: #7DF9FF;
  --color_blueberry: #6e6eff;
  --color_dark_blueberry: rgb(41, 41, 66);
  --color_navy_green: rgba(1, 130, 109, 0.759);
  --color_white: white;


  --color_1: white;
  --color_1_hover: rgb(126, 251, 255);


  /*________________HOME_VARIABLES________________*/
  --home_page_white_tab: white;
  --home_page_white_tab_title: #6e6eff;
  --home_page_white_tab_label: black;
  --home_page_blueberry_tab: #6e6eff;
  --home_page_blueberry_tab_title: white;
  --home_page_blueberry_tab_label: white;


  /*________________SIDEBAR_VARIABLES________________*/
  --sidebar_background: white;
  --sidebar_top_section_background: #6e6eff;
  --sidebar_title: white;
  --sidebar_label: black;
}

::selection {
  background-color: rgb(29, 29, 29);
  color: white;
}

.dark_mode {
  /*________________HOME_VARIABLES________________*/
  --home_page_white_tab: rgb(27, 27, 27);
  --home_page_white_tab_title: white;
  --home_page_white_tab_label: white;
  --home_page_blueberry_tab: #6e6eff;
  --home_page_blueberry_tab_title: white;
  --home_page_blueberry_tab_label: white;

  /*________________SIDEBAR_VARIABLES________________*/
  --sidebar_background: rgb(27, 27, 27);
  --sidebar_top_section_background: rgb(19, 19, 19);
  --sidebar_title: white;
  --sidebar_label: white;
}

* {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

html {
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

html::-webkit-scrollbar {
  display: none;
}