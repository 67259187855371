#diet_page {
    position: relative;
    height: auto;
    min-height: 90vh;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

#add_food_container {
    height: 60px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
}

#add_food_input_container {
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
}

#add_food_name_input {
    height: 100%;
    width: clamp(300px, 45vw, 600px);
    padding: 10px;
    border-right: none;
    border-radius: 12px 0 0 12px;
    font-size: 16px;
    outline: none;
    transition: all .3s ease;
}

.add_food_name_input_valid {
    border: rgba(0, 0, 0, 0.3) 1px solid;
}

.add_food_name_input_invalid {
    border: rgba(255, 0, 0, 0.8) 1px solid;
    background-color: rgba(255, 0, 0, 0.1);
}


#add_food_quantity_input {
    height: 100%;
    width: 15vw;
    max-width: 150px;
    padding: 10px;
    border-left: none;
    border-radius: 0 12px 12px 0;
    font-size: 16px;
    outline: none;
    text-align: center;
    transition: all .3s ease;
}

.add_food_quantity_input_valid {
    border: rgba(0, 0, 0, 0.3) 1px solid;
}

.add_food_quantity_input_invalid {
    border: rgba(255, 0, 0, 0.8) 1px solid;
    background-color: rgba(255, 0, 0, 0.1);
}

#add_food_quantity_input::-webkit-outer-spin-button,
#add_food_quantity_input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

#add_food_quantity_input[type=number] {
    -moz-appearance: textfield;
}

#add_food_input {
    height: 100%;
    width: 50vw;
    max-width: 600px;
    padding: 20px 30px;
    border: rgba(0, 0, 0, 0.3) 1px solid;
    border-radius: 15px;
    outline: none;
    font-family: var(--font_general);
    font-size: 20px;
    z-index: 4;
}

#add_food_button {
    height: 60px;
    width: 130px;
    border: none;
    border-radius: 30px;
    background-color: lightgreen;
    color: black;
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.5);
    outline: none;
    font-family: var(--font_general);
    font-size: 18px;
    transition: transform .2s ease;
    z-index: 4;
}

#add_food_button:hover {
    transform: scale(1.08);
}

#add_food_button:active {
    transform: scale(1);
}

#add_food_dropdown {
    position: absolute;
    top: 0;
    z-index: 2;
    width: 95vw;
    min-height: 250px;
    height: auto;
    max-height: 50vh;
    padding: 10px;
    border-radius: 15px;
    transition: all .2s ease;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;
}

#add_food_dropdown::-webkit-scrollbar {
    display: none;
}

.add_food_dropdown_off {
    opacity: 0;
    pointer-events: none;
    transform: translateY(50px);
}

.add_food_dropdown_on {
    opacity: 1;
    pointer-events: auto;
    transform: translateY(80px);
}

#food {
    height: 100px;
    width: 100px;
    padding: 10px;
    text-align: center;
    font-family: var(--font_general);
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
    box-shadow: 5px 5px 12px 2px rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    cursor: pointer;
    user-select: none;
    transition: all .2s ease;
}

#food:hover {
    transform: scale(1.15);
}

#food:active {
    transform: scale(1);
}

#diet_page_container {
    position: relative;
    height: 60vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

#foods_eaten_list {
    position: relative;
    height: auto;
    width: 35vw;
    overflow-y: scroll;
    padding: 20px;
    border-radius: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    gap: 10px;
    background-color: #ececec;
    box-shadow: 5px 5px 15px 1px rgba(0, 0, 0, 0.4)
}

#foods_eaten_list::-webkit-scrollbar {
    width: 10px;
}

#foods_eaten_list::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 10px;
    margin: 15px;
}

#foods_eaten_list::-webkit-scrollbar-thumb {
    background: rgb(169, 169, 169);
    border-radius: 10px;
}

#no_foods_yet {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#no_foods_yet button {
    width: 50%;
    min-width: 150px;
    padding: 16px;
    border: 2px black dashed;
    border-radius: 30px;
    font-family: var(--font_general);
    cursor: pointer;
    transition: all .2s ease;
}

#no_foods_yet button:hover {
    transform: scale(1.08);
}

#no_foods_yet button:active {
    transform: scale(1);
}

#food_section {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    gap: 15px;
}

#food_section p {
    font-family: var(--font_general);
    font-size: 20px;
}

#delete_food_button {
    width: 30px;
    height: 30px;
    border: none;
    background: transparent;
    user-select: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .1s ease;
}

#delete_food_button:hover {
    transform: scale(1.15);
}

#delete_food_button:active {
    transform: scale(1);
}

#delete_food_icon {
    width: 28px;
    height: 28px;
    opacity: 0.85;
}

#foods_eaten_graph {
    width: 55vw;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ececec;
    box-shadow: 5px 5px 15px 1px rgba(0, 0, 0, 0.4)
}

#foods_eaten_graph_table {
    height: 100%;
    width: 100%;
    padding: 4%;
    text-align: center;
    border-spacing: 10px;
}

#foods_eaten_graph_table p {
    cursor: default;
}

#value_name {
    font-family: var(--font_general);
}

#value_bar_container {
    position: relative;
    height: 24px;
    width: 150px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: end;
    border: 0.5px rgba(0, 0, 0, 0.2) solid;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
}

#value_bar {
    height: 100%;
    width: 0;
    max-width: 100%;
    border-radius: 5px;
    background-color: var(--color_blueberry);
    text-wrap: wrap;
    transition: all .6s ease;
}

#inbar_value_percentage {
    position: absolute;
    left: 50%;
    top: 50%;
    font-family: var(--font_general);
    transform: translate(-50%, -50%);
}

#value_quantity {
    font-family: var(--font_general);
}

#value_percentage {
    font-family: var(--font_general);
}

/*NON LOGGED SCREEN*/
#cover_non_logged {
    position: absolute;
    z-index: 30;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#options_container {
    padding: 50px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

#options_container p {
    font-family: var(--font_general);
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    user-select: none;
}

#buttons_container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3vw;
}

#cover_non_logged_label{
    position: relative;
    opacity: 0;
}

#not_logged_login_button {
    position: relative;
    opacity: 0;
    height: 46px;
    width: 130px;
    background-color: lightgreen;
    color: black;
    border: none;
    border-radius: 23px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
    cursor: pointer;
    transition: transform .2s ease;
    user-select: none;
    font-size: 18px;
}

#not_logged_login_button:hover {
    transform: scale(1.15);
}

#not_logged_login_button:active {
    transform: scale(1);
}

#vertical_bar {
    position: relative;
    opacity: 0;
    height: 38px;
    width: 1px;
    background-color: rgba(0, 0, 0, 0.4);
}

#not_logged_register_button {
    position: relative;
    opacity: 0;
    height: 46px;
    width: 130px;
    background-color: rgb(186, 85, 85);
    color: white;
    border: none;
    border-radius: 23px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
    cursor: pointer;
    transition: transform .2s ease;
    user-select: none;
    font-size: 18px;
}

#not_logged_register_button:hover {
    transform: scale(1.15);
}

#not_logged_register_button:active {
    transform: scale(1);
}

#add_food_container_cover {
    position: absolute;
}

.add_food_container_cover_on {
    height: 100vh;
    width: 100vw;
    top: 0;
    background-color: transparent;
}

@keyframes not_logged_screen_blur {
    from {}

    to {
        backdrop-filter: blur(2px);
        background-color: rgba(255, 255, 255, 0.6);
    }
}

@keyframes move_in {
    from {
        top: 400px
    }

    to {
        top: 0;
        opacity: 1;
    }
}

/*_____________________SCREEN < 900PX________________________*/
@media screen and (max-width: 900px) {
    #add_food_container {
        height: auto;
        flex-direction: column;
        align-items: center;
        gap: 14px;
    }

    #add_food_input_container {
        height: 50px;
    }

    #add_food_name_input {
        width: 65vw;
        min-width: none;
        padding: 1%;
        font-size: 14px;
    }

    #add_food_quantity_input {
        width: 25vw;
        min-width: none;
        padding: 1%;
        font-size: 14px;
    }

    #add_food_button {
        color: black;
        height: 50px;
        width: 100px;
        border-radius: 26px;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
        font-size: 16px;
    }

    #add_food_dropdown {
        padding: 10px;
        gap: 10px;
    }

    .add_food_dropdown_off {
        pointer-events: none;
        opacity: 0;
    }

    .add_food_dropdown_on {
        opacity: 1;
        pointer-events: auto;
        transform: translateY(125px);
    }

    #food {
        height: 80px;
        width: 80px;
        padding: 5px;
        font-size: 14px;
    }

    #diet_page_container {
        height: 70vh;
        width: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }

    #foods_eaten_list {
        height: 20vh;
        width: 90vw;
    }

    #foods_eaten_list p {
        font-size: 16px;
    }

    #foods_eaten_graph {
        width: 90vw;
        height: 35vh;
        font-size: 16px;
    }

    #foods_eaten_graph_table {
        padding: 2%;
        border-spacing: auto;
    }

    #foods_eaten_graph_table p {
        font-size: 12px;
    }

    #value_bar_container {
        height: 16px;
        width: 100px;
        border-radius: 12px;
    }

    #value_bar {
        border-radius: 5px;
    }

    #value_percentage {
        display: none;
    }
}