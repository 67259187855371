#div_register_page {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 92vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

#register_form {
  padding: 20px;
  height: auto;
  min-height: 75vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}

#register_title {
  font-family: var(--font_general);
  font-size: 36px;
  font-weight: 800;
  letter-spacing: 2px;
  cursor: default;
  user-select: none;
}

#input_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 50px;
}

#left_column_input_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

#right_column_input_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.register_input_container {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  transition: all .3s ease;
}


/*_____________________________________ERRORS_SECTION_____________________________________*/
/*FIRST NAME*/
#fName_error_tab {
  position: absolute;
  z-index: 10;
  height: 60%;
  width: 95%;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: rgb(255, 90, 90);
  transition: all .2s ease;
}

.fName_error_tab_off {
  transform: translate(-50%, 120%);
  opacity: 0;
}

.fName_error_tab_on {
  transform: translate(-50%, 140%);
  opacity: 1;
}

#fName_error_tab p {
  font-family: var(--font_general);
  font-size: 16px;
  color: white;
}

/*LAST NAME*/
#lName_error_tab {
  position: absolute;
  z-index: 10;
  height: 60%;
  width: 95%;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: rgb(255, 90, 90);
  transition: all .2s ease;
}

.lName_error_tab_off {
  transform: translate(-50%, 120%);
  opacity: 0;
}

.lName_error_tab_on {
  transform: translate(-50%, 140%);
  opacity: 1;
}

#lName_error_tab p {
  font-family: var(--font_general);
  font-size: 16px;
  color: white;
}

/*BIRTHDAY*/
#bDay_error_tab {
  position: absolute;
  z-index: 10;
  height: 60%;
  width: 95%;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: rgb(255, 90, 90);
  transition: all .2s ease;
}

.bDay_error_tab_off {
  transform: translate(-50%, 120%);
  opacity: 0;
}

.bDay_error_tab_on {
  transform: translate(-50%, 140%);
  opacity: 1;
}

#bDay_error_tab p {
  font-family: var(--font_general);
  font-size: 16px;
  color: white;
}

/*WEIGHT*/
#weight_error_tab {
  position: absolute;
  z-index: 10;
  height: 60%;
  width: 95%;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: rgb(255, 90, 90);
  transition: all .2s ease;
}

.weight_error_tab_off {
  transform: translate(-50%, 120%);
  opacity: 0;
}

.weight_error_tab_on {
  transform: translate(-50%, 140%);
  opacity: 1;
}

#weight_error_tab p {
  font-family: var(--font_general);
  font-size: 16px;
  color: white;
}

/*HEIGHT*/
#height_error_tab {
  position: absolute;
  z-index: 10;
  height: 60%;
  width: 95%;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: rgb(255, 90, 90);
  transition: all .2s ease;
}

.height_error_tab_off {
  transform: translate(-50%, 120%);
  opacity: 0;
}

.height_error_tab_on {
  transform: translate(-50%, 140%);
  opacity: 1;
}

#height_error_tab p {
  font-family: var(--font_general);
  font-size: 16px;
  color: white;
}

/*EMAIL*/
#email_error_tab {
  position: absolute;
  z-index: 10;
  height: 60%;
  width: 95%;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: rgb(255, 90, 90);
  transition: all .2s ease;
}

.email_error_tab_off {
  transform: translate(-50%, 120%);
  opacity: 0;
}

.email_error_tab_on {
  transform: translate(-50%, 140%);
  opacity: 1;
}

#email_error_tab p {
  font-family: var(--font_general);
  font-size: 16px;
  color: white;
}


/*PASSWORD*/
#password_error_tab {
  position: absolute;
  z-index: 10;
  min-height: 60%;
  height: auto;
  width: 95%;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: rgb(255, 90, 90);
  transition: all .2s ease;
}

.password_error_tab_off {
  transform: translateX(-50%);
  top: 95%;
  opacity: 0;
  pointer-events: none;
}

.password_error_tab_on {
  transform: translateX(-50%);
  top: 105%;
  opacity: 1;
}

#password_error_message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding: 10px;
}

#password_error_message p {
  font-family: var(--font_general);
  font-size: 14px;
  color: white;
  align-self: center;
}

#password_error_message li {
  font-family: var(--font_general);
  font-size: 14px;
  color: white;
}

#password_error_message li::marker {
  font-size: 20px;
}

/*CONFIRM PASSWORD*/
#confPassword_error_tab {
  position: absolute;
  z-index: 10;
  height: 60%;
  width: 95%;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: rgb(255, 90, 90);
  transition: all .2s ease;
}

.confPassword_error_tab_off {
  transform: translate(-50%, 120%);
  opacity: 0;
  pointer-events: none;
}

.confPassword_error_tab_on {
  transform: translate(-50%, 140%);
  opacity: 1;
}

#confPassword_error_tab p {
  font-family: var(--font_general);
  font-size: 16px;
  color: white;
}

.register_label {
  padding-left: 10px;
  font-family: var(--font_general);
  font-size: 14px;
  user-select: none;
  color: black;
}

.register_input {
  height: 55px;
  width: 450px;
  padding-left: 25px;
  background-color: transparent;
  color: black;
  border: none;
  border-bottom: rgba(0, 0, 0, 0.4) 2px solid;
  outline: none;
  font-family: var(--font_general);
  font-size: 20px;
  transition: all .2s ease;
}

.register_input:focus {
  border-bottom: rgb(0, 0, 0) 2px solid;
}

#register_weight_input::-webkit-outer-spin-button,
#register_weight_input::-webkit-inner-spin-button,
#register_height_input::-webkit-outer-spin-button,
#register_height_input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

#register_show_hide_password,
#register_show_hide_confirm_password {
  position: absolute;
  right: 15px;
  top: 40%;
  width: 30px;
  cursor: pointer;
}

#register_continue_button {
  height: 40px;
  width: 150px;
  outline: none;
  border: none;
  background-color: lightgreen;
  border-radius: 20px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  font-family: var(--font_general);
  font-size: 16px;
  font-weight: 500;
  user-select: none;
  cursor: pointer;
  transition: all .15s ease;
}

#register_continue_button:hover {
  transform: scale(1.1);
}

#register_continue_button:active {
  transform: scale(1);
}

#email_sent_section {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
}

#email_sent_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12vh;
}

#email_sent_label {
  font-family: var(--font_general);
  font-size: 26px;
  cursor: default;
  width: 70vw;
  max-width: 500px;
  min-width: 200px;
  text-align: center;
  color: black;
  opacity: 0;
  pointer-events: none;
}

#login_button_email_sent {
  height: 45px;
  width: 150px;
  border-radius: 20px;
  color: white;
  font-size: 18px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  border: none;
  background-color: var(--color_blueberry);
  user-select: none;
  cursor: pointer;
  opacity: 0;
  transition: all .2s ease;
}

#login_button_email_sent:hover {
  scale: 1.08;
}

#login_button_email_sent:active {
  scale: 1;
}

@keyframes register_page_blur {
  from {
    opacity: 0;
    pointer-events: none;
  }

  to {
    opacity: 1;
    pointer-events: auto;
    background-color: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(3px);
  }
}

@keyframes register_page_move_in {
  from {
    transform: translateY(400px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
    pointer-events: all;
  }
}

@media screen and (max-width: 1100px) {

  #register_form {
    padding: 0;
    padding-top: 50px;
    padding-bottom: 50px;
    gap: 40px;
  }

  #register_title {
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 1px;
  }

  .register_label {
    padding-left: 8px;
    font-size: 12px;
  }

  .register_input {
    height: 50px;
    width: 70vw;
    max-width: 400px;
    min-width: 200px;
    padding-left: 18px;
    font-size: 16px;
  }

  #input_container {
    flex-direction: column;
    gap: 20px;
  }

  #left_column_input_container {
    gap: 20px;
  }

  #right_column_input_container {
    gap: 20px;
  }

  #email_sent_container p {
    font-size: 22px;
    width: 90vw;
    max-width: 500px;
    min-width: 180px;
  }
}