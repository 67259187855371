@keyframes blur_appear {
    from {}

    to {
        backdrop-filter: blur(1.3px);
        background-color: rgba(255, 255, 255, 0.4);
    }
}

@keyframes blur_disappear {
    from {
        backdrop-filter: blur(1.3px);
        background-color: rgba(255, 255, 255, 0.4);
    }

    to {}
}

@keyframes appear {
    from {
        scale: 0;
        opacity: 0;
    }

    70% {
        scale: 1.2;
    }

    to {
        scale: 1;
        opacity: 1;
    }
}

@keyframes disappear {
    from {
        scale: 1;
        opacity: 1;
    }

    30% {
        scale: 1.2;
    }

    50% {
        opacity: 1;
    }

    to {
        scale: 0;
        opacity: 0;
    }
}

#login_tab_container {
    position: fixed;
    z-index: 40;
    top: 100px;
    left: 0px;
    height: calc(100% - 100px);
    width: 100%;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: blur_appear .3s ease 1 forwards;
    perspective: 800px;
}

#login_tab {
    position: relative;
    height: 570px;
    width: 450px;
    border-radius: 30px;
    box-shadow: 5px 5px 14px rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    pointer-events: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 18px;
    animation: appear .3s cubic-bezier(0.190, 0.465, 0.690, 1.370) forwards 1;
}

#login_tab_close_button {
    position: absolute;
    height: 50px;
    width: 50px;
    margin: 10px;
    right: 0;
    top: 0;
    border-radius: 25px;
    border: none;
    background-color: white;
    cursor: pointer;
    user-select: none;
    transition: all .2s ease;
    display: flex;
    justify-content: center;
    align-items: center;
}

#login_tab_close_button_icon {
    position: relative;
    width: 22px;
    height: 22px;
    transition: all .2s ease;
}

#login_tab_close_button:hover #login_tab_close_button_icon {
    transform: scale(1.1);
}

#login_tab_close_button:active #login_tab_close_button_icon {
    transform: scale(0.92);
}

/*              TO FIX
  .login_tab_off {
    transform: translate(60vw, -60vh) scale(0.4) ;
  }
  
  .login_tab_on {
    transform: translate(0,0) scale(1);
  }*/


#login_tab_form_input {
    height: auto;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 18px;
}

#login_title {
    font-family: var(--font_general);
    font-size: 38px;
}

#login_error_container {
    position: relative;
    width: 100%;
    height: 20px;
    transition: all .2s ease;
}

#login_error {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    color: red;
    text-align: center;
    font-family: var(--font_general);
    font-size: 20px;
    user-select: none;
    transition: all .2s ease;
}

.login_error_hidden {
    transform: scaleY(0);
    height: 0;
    opacity: 0;
}

.login_error_shown {
    transform: scaleY(1);
    height: auto;
    opacity: 1;
}

.login_input {
    height: 55px;
    width: 300px;
    padding: 20px;
    border: rgba(0, 0, 0, 0.3) 1px solid;
    border-radius: 22px;
    outline: none;
    font-family: var(--font_general);
    font-size: 16px;
    transition: all .2s ease;
}

.login_input_invalid {
    height: 55px;
    width: 300px;
    padding: 20px;
    border: rgba(255, 0, 0, 0.6) 2px solid;
    border-radius: 22px;
    outline: none;
    font-family: var(--font_general);
    font-size: 16px;
    transition: all .2s ease;
}

#login_password_input_container {
    position: relative;
}

#show_password_section {
    position: absolute;
    height: auto;
    width: auto;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: transparent;
    cursor: pointer;
    background-color: red;
}

#show_password_icon {
    position: absolute;
    right: 6px;
    width: 27px;
    padding: 2x;
    transition: all .2s ease;
    border-radius: 100%;
    background-color: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(1px);
}

#login_remember_section {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font_general);
    font-size: 16px;
    gap: 16px;
    user-select: none;
}

#login_remember_button {
    width: 16px;
    height: 16px;
    cursor: pointer;
}

#login_remember_section label {
    cursor: pointer;
}

#login_form_button {
    height: 40px;
    width: 150px;
    outline: none;
    border: none;
    background-color: lightgreen;
    border-radius: 20px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
    font-family: var(--font_general);
    font-size: 16px;
    font-weight: 500;
    transition: all .1s ease;
    user-select: none;
    cursor: pointer;
}

#login_form_button:active {
    transform: scale(0.95);
}


#login_tab hr {
    width: 50%;
    height: 1px;
    margin: 3px;
    border: rgba(0, 0, 0, 0.5) .7px solid;
    border-radius: 1px;
}

#login_tab_bottom_section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 18px
}

#login_tab_bottom_section p {
    font-family: var(--font_general);
}

#login_tab_register_button {
    height: 40px;
    width: 150px;
    outline: none;
    border: none;
    background-color: rgba(255, 0, 0, 0.653);
    color: white;
    border-radius: 20px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
    font-family: var(--font_general);
    font-size: 16px;
    font-weight: 500;
    transition: all .1s ease;
    user-select: none;
    cursor: pointer;
}

#login_tab_register_button:active {
    transform: scale(0.95);
}

#recover_password {
    font-family: var(--font_general);
    color: rgba(0, 0, 255, 0.66);
    background-color: transparent;
    border: none;
    cursor: pointer;
    border-radius: 10px;
    padding: 8px;
    font-size: 14px;
}

@media screen and (max-width:900px) {
    @keyframes blur_appear {
        from {}

        to {
            backdrop-filter: blur(1.3px);
            background-color: rgba(255, 255, 255, 0.4);
        }
    }

    @keyframes blur_disappear {
        from {
            backdrop-filter: blur(1.3px);
            background-color: rgba(255, 255, 255, 0.4);
        }

        to {}
    }

    @keyframes appear {
        from {
            scale: 0;
            opacity: 0;
        }

        70% {
            scale: 1.05;
        }

        to {
            scale: 1;
            opacity: 1;
        }
    }

    @keyframes disappear {
        from {
            scale: 1;
            opacity: 1;
        }

        30% {
            scale: 1.05;
        }

        50% {
            opacity: 1;
        }

        to {
            scale: 0;
            opacity: 0;
        }
    }

    #login_tab_container {
        animation: blur_appear .3s ease 1 forwards;
        perspective: 700px;
    }

    #login_tab {
        position: relative;
        height: 570px;
        width: 80vw;
        min-width: 350px;
        border-radius: 30px;
        box-shadow: 5px 5px 14px rgba(0, 0, 0, 0.6);
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        pointer-events: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 18px;
        animation: appear .3s cubic-bezier(0.190, 0.465, 0.690, 1.370) forwards 1;
    }

    #login_tab_close_button {
        position: absolute;
        height: 50px;
        width: 50px;
        margin: 10px;
        right: 0;
        top: 0;
        border-radius: 25px;
        border: none;
        background-color: white;
        cursor: pointer;
        user-select: none;
        transition: all .2s ease;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #login_tab_close_button_icon {
        position: relative;
        width: 22px;
        height: 22px;
        transition: all .2s ease;
    }

    #login_tab_close_button:hover #login_tab_close_button_icon {
        transform: scale(1.1);
    }

    #login_tab_close_button:active #login_tab_close_button_icon {
        transform: scale(0.92);
    }

    #login_tab_form_input {
        height: auto;
        width: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 18px;
    }

    #login_title {
        font-family: var(--font_general);
        font-size: 38px;
    }

    .login_input {
        height: 55px;
        width: 300px;
        padding: 20px;
        border: rgba(0, 0, 0, 0.3) 1px solid;
        border-radius: 22px;
        outline: none;
        font-family: var(--font_general);
        font-size: 16px;
    }

    #login_remember_section {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: var(--font_general);
        font-size: 16px;
        gap: 16px;
        user-select: none;
    }

    #login_remember_button {
        width: 16px;
        height: 16px;
        cursor: pointer;
    }

    /*#login_remember_button:checked {}*/

    #login_remember_section label {
        cursor: pointer;
    }

    #login_form_button {
        height: 40px;
        width: 150px;
        outline: none;
        border: none;
        background-color: lightgreen;
        border-radius: 20px;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
        font-family: var(--font_general);
        font-size: 16px;
        font-weight: 500;
        transition: all .1s ease;
        user-select: none;
        cursor: pointer;
    }

    #login_form_button:active {
        transform: scale(0.95);
    }


    #login_tab hr {
        width: 50%;
        height: 1px;
        margin: 3px;
        border: rgba(0, 0, 0, 0.5) .7px solid;
        border-radius: 1px;
    }

    #login_tab_bottom_section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 18px
    }

    #login_tab_bottom_section p {
        font-family: var(--font_general);
    }

    #login_tab_register_button {
        height: 40px;
        width: 150px;
        outline: none;
        border: none;
        background-color: rgba(255, 0, 0, 0.653);
        color: white;
        border-radius: 20px;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
        font-family: var(--font_general);
        font-size: 16px;
        font-weight: 500;
        transition: all .1s ease;
        user-select: none;
        cursor: pointer;
    }

    #login_tab_register_button:active {
        transform: scale(0.95);
    }

    #recover_password {
        font-family: var(--font_general);
        color: rgba(0, 0, 255, 0.66);
        cursor: pointer;
        border-radius: 10px;
        padding: 7px;
    }

}