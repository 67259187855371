#home_page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
    font-family: var(--font_general);
    background-color: var(--home_page_white_tab);
    perspective: 1000px;
}

.home_tab {
    height: 100vh;
    width: 100%;
    aspect-ratio: 16/9;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5vw;
    user-select: none;
}

.home_section {
    width: 600px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12vh;
    background-color: transparent;
}

.home_section_title {
    color: var(--home_page_white_tab_title);
    font-size: 40px;
    font-weight: 600;
    width: 40vw;
    max-width: 500px;
    min-width: 200px;
}

.home_section_label {
    font-family: var(--font_general);
    font-size: 24px;
    user-select: none;
    width: 80vw;
    max-width: 600px;
    min-width: 180px;
    color: var(--home_page_white_tab_label);
}

.home_tab_blueberry .home_section_label span {
    font-size: 28px;
    font-weight: bold;
}

/*BLUEBERRY TAB*/
.home_tab_blueberry {
    height: 100vh;
    width: 100%;
    aspect-ratio: 16/9;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5vw;
    user-select: none;
    background-color: var(--home_page_blueberry_tab);
}

.home_tab_blueberry .home_section_title {
    font-size: 40px;
    font-weight: 600;
    color: var(--home_page_blueberry_tab_title);
    width: 40vw;
    max-width: 500px;
    min-width: 200px;
}

.home_tab_blueberry .home_section_label {
    font-family: var(--font_general);
    font-size: 24px;
    user-select: none;
    color: var(--home_page_blueberry_tab_label);
}

#first_tab_background {
    width: 40vw;
    min-width: 250px;
    border-radius: 100%;
}

#second_tab_background {
    width: 48vw;
    min-width: 250px;
}

#third_tab_background {
    width: 40vw;
    min-width: 250px;
}

@media screen and (max-width: 900px) {

    .home_tab {
        height: 80vh;
        max-height: 750px;
        flex-direction: column;
        gap: 8vh;
    }

    .home_tab_blueberry {
        height: 80vh;
        max-height: 750px;
        flex-direction: column;
        gap: 8vh;
    }

    .home_section {
        width: 90vw;
    }

    .home_section_title {
        font-size: 24px;
    }

    .home_section_label {
        font-size: 18px;
    }

    .home_tab_blueberry .home_section_title {
        font-size: 24px;
    }

    .home_tab_blueberry .home_section_label {
        font-size: 18px;
    }

    #first_tab_background {
        width: 50vw;
        min-width: 150px;
        border-radius: 50%;
    }

    #second_tab_background {
        width: 50vw;
        min-width: 150px;
    }

    #third_tab_background {
        width: 50vw;
        min-width: 150px;
    }
}