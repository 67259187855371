#historic_page {
    position: relative;
    height: 92vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font_general);
}

#historic_container {
    position: relative;
    padding: 15px;
    height: 94%;
    width: 94%;
    background-color: #eaeaea;
    box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

#filter_switch {
    right: 10px;
    top: 10px;
    padding: 10px;
    font-size: 18px;
    border: none;
    background-color: var(--color_blueberry);
    color: white;
    border-radius: 10px;
    outline: none;
    cursor: pointer;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
    transition: all .1s ease;
}

#filter_switch:focus {
    scale: 1.08;
}


#historic_header {
    width: 100%;
    padding: 8px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#historic_header_title {
    width: 50%;
    text-align: center;
    font-size: 26px;
    font-weight: 700;
    text-transform: uppercase;
    cursor: default;
}

#filter_switch {
    right: 10px;
    top: 10px;
    padding: 10px;
    font-size: 18px;
    border: none;
    background-color: var(--color_blueberry);
    color: white;
    border-radius: 10px;
    outline: none;
}

#historic_content {
    height: 90%;
    width: 90%;
    background-color: #dfdfdf;
    border-radius: 10px;
    box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.5) inset;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    perspective: 1000px;
}

#historic_content_foods {
    position: relative;
    height: 100%;
    width: 50%;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: safe;
}

#historic_content_foods_title {
    padding: 30px 0;
    padding-right: 50%;
    text-align: center;
    font-size: 24px;
    cursor: default;
    text-transform: capitalize;
}

#historic_content_foods_container {
    height: auto;
    max-height: 100%;
    width: 100%;
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    gap: 20px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    background-color: transparent;
}

#historic_content_foods_container::-webkit-scrollbar {
    width: 0
}

#no_foods_registered {
    height: 50%;
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#no_foods_registered p {
    color: black;
    font-size: 20px;
    padding: 30px 35px;
    background-color: #e2e2e2;
    border-radius: 100px;
    border: 2px black dashed;
}

#historic_content_food {
    padding: 15px 30px;
    background-color: #e5e5e5;
    border-radius: 10px;
    cursor: default;
}

#graphs {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 10px;
    cursor: default;
}

#pie_chart {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    opacity: 0;
    /* background: conic-gradient(#ef5350 0% 30%,
            #ffd904 30% 50%,
            #4e27ff 50% 60%,
            #66bb6a 60% 75%,
            #ab47bc 75% 85%,
            #86c9ff 85% 100%); */
    /* background: conic-gradient(#ffd904 0% 21.1%,
            #4e27ff 21.3% 48.2%,
            #66bb6a 48.7% 100%); */
}

#pie_chart_values {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
}

#daily_average_p{
    font-family: var(--font_general);
    font-size: 21px;
    font-weight: 600;
}

#pie_chart_table {
    border-spacing: 15px;
}

#pie_chart_table td {
    text-align: center;
}

#calorie {
    color: #ef5350;
    font-size: 20px;
    font-weight: 600;
}

#proteine {
    color: #ffd904;
    font-size: 20px;
    font-weight: 600;
}

#grassi {
    color: #4e27ff;
    font-size: 20px;
    font-weight: 600;
}

#carboidrati {
    color: #66bb6a;
    font-size: 20px;
    font-weight: 600;
}

#zuccheri {
    color: #ab47bc;
    font-size: 20px;
    font-weight: 600;
}

#fibra_alimentare {
    color: #86c9ff;
    font-size: 20px;
    font-weight: 600;
}



#historic_cover_non_logged {
    position: absolute;
    z-index: 30;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#historic_options_container {
    padding: 50px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

#historic_options_container p {
    font-family: var(--font_general);
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    user-select: none;
}

#historic_buttons_container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 35px;
}

#historic_options_label{
    position: relative;
    opacity: 0;
}

#historic_not_logged_login_button {
    position: relative;
    opacity: 0;
    height: 46px;
    width: 130px;
    background-color: lightgreen;
    color: black;
    border: none;
    border-radius: 23px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
    cursor: pointer;
    transition: transform .2s ease;
    user-select: none;
    font-size: 18px;
}

#historic_not_logged_login_button:hover {
    transform: scale(1.15);
}

#historic_not_logged_login_button:active {
    transform: scale(1);
}

#historic_vertical_bar {
    position: relative;
    opacity: 0;
    height: 38px;
    width: 1px;
    background-color: rgba(0, 0, 0, 0.4);
}

#historic_not_logged_register_button {
    position: relative;
    opacity: 0;
    height: 46px;
    width: 130px;
    background-color: rgb(186, 85, 85);
    color: white;
    border: none;
    border-radius: 23px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
    cursor: pointer;
    transition: transform .2s ease;
    user-select: none;
    font-size: 18px;
}

#historic_not_logged_register_button:hover {
    transform: scale(1.15);
}

#historic_not_logged_register_button:active {
    transform: scale(1);
}



@keyframes filling_up {
    from {
        rotate: 0;
        opacity: 0;
        scale: 0;
    }

    65% {
        scale: 1.2;
    }

    to {
        opacity: 1;
        rotate: 2turn;
        scale: 1;
        box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.4);
    }
}

@keyframes flip_graph_card_1 {
    from {
        transform: rotateX(0);
    }

    to {
        transform: rotateX(0.25turn);
    }
}

@keyframes flip_graph_card_2 {
    from {
        transform: rotateX(0.75turn);
    }

    to {
        transform: rotateX(1turn);
    }
}

@keyframes historic_not_logged_screen_blur {
    from {}

    to {
        backdrop-filter: blur(2px);
        background-color: rgba(255, 255, 255, 0.6);
    }
}

@keyframes historic_move_in {
    from {
        top: 400px
    }

    to {
        top: 0;
        opacity: 1;
    }
}

@media screen and (max-width: 1000px) {
    #historic_page {
        height: 120vh;
    }

    #historic_container {
        padding: 10px;
        height: 95%;
        width: 95%;
    }

    #historic_header {
        padding: 8px 30px;
    }

    #historic_header_title {
        font-size: 20px;
    }

    #historic_content {
        height: 90%;
        flex-direction: column-reverse;
    }

    #historic_content_foods {
        position: relative;
        height: 50%;
        width: 100%;
        padding: 5px;
        background-color: transparent;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    #historic_content_foods_title {
        padding: 10px 0;
        padding-right: 50%;
        text-align: center;
        font-size: 18px;
    }

    #historic_content_foods_container {
        width: 70%;
        padding: 15px;
        gap: 8px;
        max-height: 100%;
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        background-color: transparent;
    }

    #historic_content_foods_container::-webkit-scrollbar {
        width: 0
    }

    #no_foods_registered {
        height: 100%;
        padding: 6px 16px;
    }

    #no_foods_registered p {
        font-size: 16px;
        padding: 20px 25px;
    }

    #historic_content_food {
        padding: 10px 20px;
        border-radius: 8px;
    }

    #graphs {
        width: 100%;
        height: 50%;
    }

    #pie_chart {
        width: 200px;
        height: 200px;
        border-radius: 50%;
    }

    @keyframes filling_up {
        from {
            rotate: 0;
            opacity: 0;
            scale: 0;
        }


        50% {
            background: conic-gradient(#ef5350 0% 30%,
                    #ffd904 30% 50%,
                    #4e27ff 50% 60%,
                    #66bb6a 60% 75%,
                    #ab47bc 75% 85%,
                    #86c9ff 85% 100%);
        }

        65% {
            scale: 1.1;
        }

        to {
            opacity: 1;
            rotate: 2turn;
            scale: 1;
            box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.4);
            background: conic-gradient(#ef5350 0% 30%,
                    #ffd904 30% 50%,
                    #4e27ff 50% 60%,
                    #66bb6a 60% 75%,
                    #ab47bc 75% 85%,
                    #86c9ff 85% 100%);
        }
    }

    #pie_chart_table {
        border-spacing: 20px 10px;
    }

    #calorie {
        font-size: 18px;
    }

    #proteine {
        font-size: 18px;
    }

    #grassi {
        font-size: 18px;
    }

    #carboidrati {
        font-size: 18px;
    }

    #zuccheri {
        font-size: 18px;
    }

    #fibra_alimentare {
        font-size: 18px;
    }
}

@media screen and (max-width: 400px) {
    #pie_chart {
        width: 100px;
        height: 100px;
        border-radius: 50%;
    }
}