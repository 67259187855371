#edit_profile_page {
    position: relative;
    width: 100%;
    height: auto;
    min-height: 92vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

#edit_profile_form {
    padding: 20px;
    height: auto;
    min-height: 75vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
}

#edit_profile_title {
    font-family: var(--font_general);
    font-size: 36px;
    font-weight: 800;
    letter-spacing: 2px;
    cursor: default;
    user-select: none;
}

#edit_profile_input_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.edit_profile_input_section {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    transition: all .3s ease;
}

.edit_profile_label {
    padding-left: 10px;
    font-family: var(--font_general);
    font-size: 14px;
    user-select: none;
    color: black;
}

.edit_profile_input {
    height: 55px;
    width: 450px;
    padding-left: 25px;
    background-color: transparent;
    color: black;
    border: none;
    border-bottom: rgba(0, 0, 0, 0.4) 2px solid;
    outline: none;
    font-family: var(--font_general);
    font-size: 20px;
    transition: all .2s ease;
}

.edit_profile_input:focus {
    border-bottom: rgb(0, 0, 0) 2px solid;
}

.edit_profile_input::-webkit-outer-spin-button,
.edit_profile_input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

#edit_profile_continue_button {
    height: 40px;
    width: 150px;
    outline: none;
    border: none;
    background-color: lightgreen;
    border-radius: 20px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
    font-family: var(--font_general);
    font-size: 16px;
    font-weight: 500;
    user-select: none;
    cursor: pointer;
    transition: all .15s ease;
}

#edit_profile_continue_button:hover {
    transform: scale(1.1);
}

#register_coedit_profile_continue_buttonntinue_button:active {
    transform: scale(1);
}

/*_____________________________________ERRORS_SECTION_____________________________________*/
/*FIRST NAME*/
#edit_profile_fName_error {
    position: absolute;
    z-index: 10;
    height: 60%;
    width: 95%;
    left: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: rgb(255, 90, 90);
    transition: all .2s ease;
}

.edit_profile_fName_error_off {
    transform: translate(-50%, 120%);
    opacity: 0;
}

.edit_profile_fName_error_on {
    transform: translate(-50%, 140%);
    opacity: 1;
}

#edit_profile_fName_error p {
    font-family: var(--font_general);
    font-size: 16px;
    color: white;
}

/*LAST NAME*/
#edit_profile_lName_error {
    position: absolute;
    z-index: 10;
    height: 60%;
    width: 95%;
    left: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: rgb(255, 90, 90);
    transition: all .2s ease;
}

.edit_profile_lName_error_off {
    transform: translate(-50%, 120%);
    opacity: 0;
}

.edit_profile_lName_error_on {
    transform: translate(-50%, 140%);
    opacity: 1;
}

#edit_profile_lName_error p {
    font-family: var(--font_general);
    font-size: 16px;
    color: white;
}

/*BIRTHDAY*/
#edit_profile_bDay_error {
    position: absolute;
    z-index: 10;
    height: 60%;
    width: 95%;
    left: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: rgb(255, 90, 90);
    transition: all .2s ease;
}

.edit_profile_bDay_error_off {
    transform: translate(-50%, 120%);
    opacity: 0;
}

.edit_profile_bDay_error_on {
    transform: translate(-50%, 140%);
    opacity: 1;
}

#edit_profile_bDay_error p {
    font-family: var(--font_general);
    font-size: 16px;
    color: white;
}

/*WEIGHT*/
#edit_profile_weight_error {
    position: absolute;
    z-index: 10;
    height: 60%;
    width: 95%;
    left: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: rgb(255, 90, 90);
    transition: all .2s ease;
}

.edit_profile_weight_error_off {
    transform: translate(-50%, 120%);
    opacity: 0;
}

.edit_profile_weight_error_on {
    transform: translate(-50%, 140%);
    opacity: 1;
}

#edit_profile_weight_error p {
    font-family: var(--font_general);
    font-size: 16px;
    color: white;
}

/*HEIGHT*/
#edit_profile_height_error {
    position: absolute;
    z-index: 10;
    height: 60%;
    width: 95%;
    left: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: rgb(255, 90, 90);
    transition: all .2s ease;
}

.edit_profile_height_error_off {
    transform: translate(-50%, 120%);
    opacity: 0;
}

.edit_profile_height_error_on {
    transform: translate(-50%, 140%);
    opacity: 1;
}

#edit_profile_height_error p {
    font-family: var(--font_general);
    font-size: 16px;
    color: white;
}


@media screen and (max-width: 1100px) {
    #edit_profile_form {
        padding: 0;
        padding-top: 50px;
        padding-bottom: 50px;
        gap: 40px;
    }

    #edit_profile_title {
        font-size: 30px;
        font-weight: 600;
        letter-spacing: 1px;
    }

    .edit_profile_label {
        padding-left: 8px;
        font-size: 12px;
    }

    .edit_profile_input {
        height: 50px;
        width: 70vw;
        max-width: 400px;
        min-width: 200px;
        padding-left: 18px;
        font-size: 16px;
    }

    #edit_profile_input_container {
        flex-direction: column;
        gap: 20px;
    }
}