#footer {
    position: relative;
    height: 110px;
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: 30px;
    background-color: var(--color_dark_blueberry);
    color: white;
    text-align: center;
    font-family: var(--font_general);
    font-size: 18px;
}

#footer_links {
    flex-grow: 1;
    height: 100%;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

#footer_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

#footer_link {
    text-decoration: none;
    color: white;
    font-size: 20px;
    padding: 5px;
    border-radius: 5px;
}

#names_section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#footer_wrapper p {
    font-family: var(--font_general);
    font-size: 14px;
    font-weight: 100;
    letter-spacing: 0.81px;
}

#footer_text {
    flex-grow: 2;
    height: 100%;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

#footer_credits {
    flex-grow: 1;
    height: 100%;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 700px) {
    #footer_wrapper p {
        font-family: var(--font_general);
        font-size: 10px;
        font-weight: 100;
        letter-spacing: 0.41px;
    }

    #footer_link {
        text-decoration: none;
        color: white;
        font-size: 10px;
        border-radius: 5px;
    }
}