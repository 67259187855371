#header_container {
    position: relative;
    height: 100px;
    width: 100%;
}

#header {
    position: relative;
    z-index: 100;
    height: 100px;
    width: 100%;
    padding: 10px 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: var(--font_title);
    font-size: 22px;
    background-color: var(--color_blueberry);
}

#header_menu_button {
    cursor: pointer;
    border: none;
    background-color: transparent;
    padding: 8px;
    text-align: center;
    font-family: var(--font_general);
    transition: all .1s ease;
    user-select: none;
    outline: none;
    filter: invert(100%);
}

#header_menu_button:hover {
    transform: scale(1.18);
}

#header_menu_button:active {
    transform: scale(1);
}

#header_menu_logo {
    width: 34px;
}

#header_title {
    font-family: 'Bagel Fat One', system-ui;
    letter-spacing: 3px;
    font-weight: 200;
    font-size: 36px;
    cursor: default;
    user-select: none;
    color: white;
}

#header_account_button {
    height: 60px;
    width: 60px;
    border: none;
    border-radius: 100%;
    cursor: pointer;
    background-color: transparent;
    transition: transform .1s ease, background-color .1s ease;
    user-select: none;
    -webkit-user-drag: none;
    outline: none;
    filter: invert(100%);
}

#header_account_button:hover {
    transform: scale(1.18);
}

#header_account_button:active {
    transform: scale(1);
}

#account_icon {
    width: 40px;
}

#account_dropdown {
    position: fixed;
    z-index: 90;
    width: 18vw;
    min-width: 280px;
    height: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    transition: all .25s ease;
    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.5);
    border-radius: 30px;
    font-size: 20px;
    background-color: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(10px);
    overflow: hidden;
}

.account_dropdown_off {
    opacity: 0;
    transform: translate(180px, -100px) scale(0.1);
    right: 0px;
    top: 0px;
    pointer-events: none;
}

.account_dropdown_on {
    opacity: 1;
    right: 10px;
    top: 110px;
    transform: scale(1);
    pointer-events: auto;
}

#account_dropdown_infos {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

#account_dropdown_infos_table {
    height: 100%;
    width: 100%;
    table-layout: fixed;
    border-spacing: 12px;
}

.account_dropdown_infos_label {
    font-family: var(--font_general);
    color: black;
    font-size: 16px;
    font-weight: 300;
    text-align: end;
    width: 40%;
}

.account_dropdown_infos_value {
    font-family: var(--font_general);
    font-size: 24px;
    font-weight: 700;
    color: black;
    text-align: start;
    width: 60%;
    padding-left: 10px;
}

#accoutnt_dropdown_buttons_container {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

#edit_profile_button {
    height: 50px;
    width: 50px;
    border: none;
    border-radius: 100%;
    background-color: white;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .2s ease;
}

#edit_profile_button:hover {
    transform: scale(1.1)
}

#edit_profile_button:active {
    transform: scale(1)
}

#edit_profile_button_icon {
    width: 20px;
    user-select: none;
}

#logout_button {
    height: 50px;
    width: 50px;
    border: none;
    border-radius: 100%;
    background-color: rgb(255, 104, 104);
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .2s ease;
}

#logout_button:hover {
    transform: scale(1.1)
}

#logout_button:active {
    transform: scale(1)
}

#logout_button_icon {
    width: 20px;
    filter: invert(100%);
    user-select: none;
}

#account_dropdown_login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

#login_button {
    height: 40px;
    width: 100px;
    background-color: white;
    color: black;
    border-radius: 20px;
    border: var(--border_default);
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
    cursor: pointer;
    transition: background-color .2s ease, transform .2s ease;
    user-select: none;
}

#login_button:hover {
    transform: scale(1.1);
}

#login_button:active {
    transform: scale(1);
}

#account_dropdown_infos_cover {
    position: fixed;
}

.account_dropdown_infos_cover_on {
    z-index: 95;
    height: 100vh;
    width: 100vw;
    top: 0;
    background-color: transparent;
}

@media screen and (max-width: 900px) {

    #header {
        padding: 10px 15px;
        font-size: 24px;
        text-align: center;
    }

    #header_menu_button {
        padding: 8px;
        text-align: center;
        font-family: var(--font_general);
        transition: all .1s ease;
        user-select: none;
    }

    #header_menu_logo {
        width: 28px;
    }

    #header_title {
        font-size: 26px;
        cursor: default;
        user-select: none;
    }

    #header_account_button {
        height: 50px;
        width: 50px;
    }

    #account_icon {
        width: 35px;
    }

    #account_dropdown {
        padding: 18px;
    }

    .account_dropdown_off {
        opacity: 0;
        transform: translate(180px, -100px) scale(0.1);
        right: 0px;
        top: 0px;
        pointer-events: none;
    }

    .account_dropdown_on {
        opacity: 1;
        right: 10px;
        top: 110px;
        transform: scale(1);
        pointer-events: auto;
    }

    #account_dropdown_infos_table {
        border-spacing: 10px;
    }

    .account_dropdown_infos_label {
        font-size: 14px;
    }

    .account_dropdown_infos_value {
        font-size: 20px;
    }

    #login_button {
        height: 50px;
        width: 100px;
        padding: 5px;
        background-color: var(--color_1);
        border-radius: 25px;
        border: var(--border_default);
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
        cursor: pointer;
        transition: background-color .2s ease, transform .1s ease;
        user-select: none;
    }

    #login_button:hover {
        background-color: var(--color_1_hover);
    }

    #login_button:active {
        background-color: var(--color_1_hover);
        transform: scale(0.92);
    }
}